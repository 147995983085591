import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { fetchShiftTimes } from "src/containers/facilityDashboard/ShiftCalendar/api";

interface UseShiftTimesRequest {
  workplaceId?: string;
  qualification?: string;
}

export function useShiftTimes({ workplaceId, qualification }: UseShiftTimesRequest) {
  const queryKey = useMemo(
    () => ["shift-times", workplaceId, qualification],
    [workplaceId, qualification]
  );
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => {
      if (!workplaceId) {
        return undefined;
      }

      return fetchShiftTimes({ userId: workplaceId, qualification });
    },
    enabled: !!workplaceId,
  });

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  return { data, isLoading, invalidateCache };
}
