/* eslint-disable max-lines */
import { z } from "zod";
import { VisitType, WorkerRequirements } from "../ExperimentalHomeHealth/Cases/types";
import { CbhFeatureFlag } from "./CbhFeatureFlag";

export enum Status {
  ON = "ON",
  OFF = "OFF",
}

export enum GenericOutageBannerSeverity {
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
}

export enum PlacementAccessControlStatus {
  OFF = "off",
  VIEW = "view",
  EDIT = "edit",
  LANDING_PAGE = "landing-page",
}

export const cbhFeatureFlags = {
  [CbhFeatureFlag.SKILLS_ASSESSMENT_CONFIGURATION]: z.object({
    isAutomationToAutomaticallyPassWorkerOn: z.boolean(),
  }),
  [CbhFeatureFlag.MASS_SHIFT_UPLOAD]: z.boolean(),
  [CbhFeatureFlag.SHOULD_SHOW_WORKER_ETA]: z.boolean(),
  [CbhFeatureFlag.ENABLE_NOTIFICATION_PREFERENCES]: z.boolean(),
  [CbhFeatureFlag.BASE_RATE_PAY_FILTERS]: z.number(),
  [CbhFeatureFlag.BASE_RATE_MULTIPLE_PAY_FILTERS]: z.number(),
  [CbhFeatureFlag.NLC_STATES]: z.object({
    active_states: z.array(z.string()),
  }),
  [CbhFeatureFlag.PERSONAL_ID_SUBTYPES]: z.object({
    manual: z.array(z.string()),
    stripe: z.array(z.string()),
  }),
  [CbhFeatureFlag.HCP_MINIMUM_CHARGE_RATES]: z.record(z.string(), z.number()).optional(),
  [CbhFeatureFlag.WORKER_RATINGS_CAROUSEL]: z.object({
    frequencyInDays: z.number().optional(),
  }),
  [CbhFeatureFlag.PRIORITY_ACCESS]: z.record(
    z.string(),
    z.union([z.boolean(), z.number(), z.record(z.string(), z.union([z.boolean(), z.number()]))])
  ),
  [CbhFeatureFlag.SUPPORT_LINKS]: z.record(z.string(), z.string()),
  [CbhFeatureFlag.MANAGE_NFC_TAGS]: z.boolean(),
  [CbhFeatureFlag.CALIFORNIA_TIMESHEET]: z.boolean(),
  [CbhFeatureFlag.WORKER_SERVICE_FOR_ADMIN_PANEL]: z.boolean(),
  [CbhFeatureFlag.WORKER_SERVICE_MIGRATED_API_MAP]: z.record(z.string(), z.boolean()),
  [CbhFeatureFlag.VARIABLE_CHARGE_STANDARD]: z.boolean(),
  [CbhFeatureFlag.RECOMMENDED_RATE_FOR_CHARGE_BLOCKED_FACILITIES]: z.object({
    enabled: z.boolean(),
  }),
  [CbhFeatureFlag.TIME_NEGOTIATION_ACTIVATION]: z.object({
    global: z.boolean().optional(),
    id: z.array(z.string()).optional(),
    msa: z.array(z.string()).optional(),
    state: z.array(z.string()).optional(),
  }),
  [CbhFeatureFlag.RATE_NEGOTIATION_ACTIVATION]: z.object({
    global: z.boolean().optional(),
    id: z.array(z.string()).optional(),
    msa: z.array(z.string()).optional(),
    state: z.array(z.string()).optional(),
  }),
  [CbhFeatureFlag.RATE_NEGOTIATION_COMMS_EMAIL]: z.boolean(),
  [CbhFeatureFlag.RATE_NEGOTIATION_COMMS_SMS]: z.boolean(),
  [CbhFeatureFlag.MY_ACCOUNT_PAGE]: z.boolean(),
  [CbhFeatureFlag.RATE_NEGOTIATION_LIMITS]: z.record(z.string(), z.string()),
  [CbhFeatureFlag.RATE_NEGOTIATION_DISPLAY_WORKER_RATING]: z.boolean(),
  [CbhFeatureFlag.MARKETING_CONVERSION_QUALIFICATIONS_WHITELIST]: z.object({
    qualifications: z.array(z.string()),
  }),
  [CbhFeatureFlag.NEW_USERS_PAGE]: z.boolean(),
  [CbhFeatureFlag.BULK_UPDATE_EMPLOYEE_ROLES]: z.boolean(),
  [CbhFeatureFlag.NOTIFICATION_CENTER_ACTIVE]: z.boolean(),
  [CbhFeatureFlag.NOTIFICATION_CENTER_COMMS_ASSIGNED]: z.boolean(),
  [CbhFeatureFlag.NOTIFICATION_CENTER_COMMS_UNASSIGNED]: z.boolean(),
  [CbhFeatureFlag.SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION]: z.boolean(),
  [CbhFeatureFlag.MAKE_RATING_FEEDBACK_REQUIRED]: z.boolean(),
  [CbhFeatureFlag.SHOULD_SHOW_LIVE_MAP_VIEW]: z.boolean(),
  [CbhFeatureFlag.AUTO_EXPAND_SHIFTS_PANEL_IN_CALENDAR_PAGE]: z.boolean(),
  [CbhFeatureFlag.WORKPLACE_ONBOARDING_STEPS_AB_TESTING]: z.object({
    orders: z.array(z.enum(["favorites", "shifts"])),
  }),
  [CbhFeatureFlag.POST_SHIFT_OPTIONS_AB_TESTING]: z.object({
    orders: z.array(z.enum(["block-shifts", "per-diem"])),
  }),
  [CbhFeatureFlag.HCF_ONBOARDING]: z.object({
    minimumWorkplaceCreationDate: z.string().optional(),
    daysUntilOnboardingOverdue: z.number().optional(),
  }),
  [CbhFeatureFlag.WORKPLACE_BLOCK_SHIFTS_TYPE_FORMS]: z.object({
    intermediate: z.string(),
    worker: z.string(),
  }),
  [CbhFeatureFlag.USP_CLIENT_SETTINGS]: z.object({
    enabled: z.boolean(),
    leadTime: z.number(),
  }),
  [CbhFeatureFlag.WORKPLACE_SELF_ONBOARDING_CTA_COPY]: z.object({
    addFavorites: z.string(),
    bookShifts: z.string(),
  }),
  [CbhFeatureFlag.IN_APP_CHAT_LIMITED_DAYS_AFTER_BOOKED_SHIFT]: z.object({
    days: z.number().optional(),
  }),
  [CbhFeatureFlag.SHOW_RATE_NEGOTIATION_CONTROL_TOGGLE]: z.boolean(),
  [CbhFeatureFlag.ATTENDANCE_RATE_CONFIG]: z.object({
    numberOfDays: z.number(),
  }),
  [CbhFeatureFlag.VARIABLE_CHARGE_REVAMPED_ACCESS_CONTROL]: z.boolean(),
  [CbhFeatureFlag.WORKPLACE_SELF_SIGNUP]: z.object({
    selfSignUpApiEnabled: z.boolean().optional(),
    signContractJotForm: z.string().optional(),
    suspiciousDomains: z.array(z.string()).optional(),
    potentialHCPQualifications: z.array(z.string()).optional(),
    showResendEmailCTA: z.boolean().optional(),
  }),
  [CbhFeatureFlag.SHOULD_SHOW_CONTRACT_TERMS]: z.boolean(),
  [CbhFeatureFlag.WORKPLACE_REFERRALS]: z.object({
    workplaceToWorkplace: z.boolean(),
  }),
  [CbhFeatureFlag.REMOVE_ADDED_MANUAL_PAY_WHEN_MANUAL_CHARGE]: z.object({
    include: z.array(z.string()),
  }),
  [CbhFeatureFlag.AVAILABLE_WORKERS_SHIFT_INFO_TEXT]: z.string(),
  [CbhFeatureFlag.MAPLE_INTEGRATION]: z.object({
    allApisThroughBgJobs: z.boolean(),
    enableApi: z.boolean(),
    enableWebhook: z.boolean(),
  }),
  [CbhFeatureFlag.ADMIN_APP_HIDE_PAYROLL_TABLE]: z.boolean(),
  [CbhFeatureFlag.UI_STRIPE_TRANSFERS]: z.boolean(),
  [CbhFeatureFlag.WORKER_BLOCK_BOOKING]: z.boolean(),
  [CbhFeatureFlag.FIREBASE_24_HOURS_EXPIRY_ENABLED]: z.boolean(),
  [CbhFeatureFlag.LD_UPGRADE_TESTING_WITH_CONTEXT]: z.boolean(),
  [CbhFeatureFlag.EDIT_SHIFT_TIME_RESTRICTION]: z.boolean(),
  [CbhFeatureFlag.VIEW_SHIFT_CHARGE_RATE]: z.boolean(),
  [CbhFeatureFlag.MAXIMUM_ALLOWED_WORKPLACE_CHARGE_RATES]: z.record(z.string(), z.number()),
  [CbhFeatureFlag.SHIFT_INVITE_CONFIGURATION]: z.object({
    expirationTime: z.object({
      defaultBookingTimeInMinutes: z.number(),
      ranges: z.array(
        z.object({
          bookingTimeInMinutes: z.number(),
          maxLeadTimeInHours: z.number(),
        })
      ),
    }),
    notifications: z.object({
      aboutToExpireTimeInMinutes: z.number(),
    }),
  }),
  [CbhFeatureFlag.ROLLOUT_CHAT_CREATION]: z.boolean(),
  [CbhFeatureFlag.ROLLOUT_BLOCK_BOOKING]: z.object({
    enablePriorityAccess: z.boolean().optional(),
    enableInstantBook: z.boolean().optional(),
    showShiftDatesInfo: z.boolean().optional(),
    isBlockBookingEnabled: z.boolean().optional(),
  }),
  [CbhFeatureFlag.ROLLOUT_QUIZ_V2_FACILITY_RULES_AI]: z.boolean(),
  [CbhFeatureFlag.SHOW_UPDATED_DNR_COPY]: z.boolean(),
  [CbhFeatureFlag.SOLVE_UNPAID_BREAKS_ROLLOUT_STAGE_WITH_TARGETING]: z.object({
    status: z.enum(["OFF", "ON", "TARGETING"]),
  }),
  [CbhFeatureFlag.ENABLE_SHIFT_SPECIFICATION]: z.boolean(),
  [CbhFeatureFlag.UNFILLED_PAST_SHIFT_DELETION]: z.boolean(),
  [CbhFeatureFlag.MUI_MIGRATION]: z.boolean(),
  [CbhFeatureFlag.HOME_HEALTH_ENABLED]: z.boolean(),
  [CbhFeatureFlag.HOME_HEALTH_ONBOARDING_ENABLED]: z.boolean(),
  [CbhFeatureFlag.HOME_HEALTH_PAY_RATES]: z.record(
    z.nativeEnum(VisitType),
    z.record(z.nativeEnum(WorkerRequirements), z.number())
  ),
  [CbhFeatureFlag.STREAKS_PRE_REWARD]: z.boolean(),
  [CbhFeatureFlag.HOME_HEALTH_WORKER_CHAT_ENABLED]: z.boolean(),
  [CbhFeatureFlag.HOME_HEALTH_FOOTER_URLS]: z.object({
    demoUrl: z.string(),
    faqUrl: z.string(),
  }),
  [CbhFeatureFlag.HOME_HEALTH_BONUS_PAYMENT]: z.boolean(),
  [CbhFeatureFlag.NON_WORKER_CLOCK_IN]: z.boolean(),
  [CbhFeatureFlag.SHOW_SHIFT_BLOCK_LABEL_ALTERNATE_COPY]: z.boolean(),
  [CbhFeatureFlag.MAGIC_CURRENCY_DISPLAY]: z.object({
    progressDisplayConfiguration: z
      .object({
        ceilingReachedDisclaimer: z.object({
          available: z.string(),
          pending: z.string(),
        }),
        instructions: z.union([z.array(z.string()), z.string()]),
      })
      .optional(),
    showAvailableMagicShiftIcons: z.boolean().default(false),
    showPendingMagicShiftIcons: z.boolean().default(false),
    showFacilityCancelFillsAlert: z.boolean().default(false),
    showShiftCounters: z.boolean().default(true),
  }),
  [CbhFeatureFlag.MAGIC_POINTS]: z.object({
    eligibleForFillGuarantee: z.boolean().optional(),
    enabled: z.boolean(),
    isMagicShiftUiHidden: z.boolean().optional(),
    monthStartBonus: z.number().optional(),
    pointsBudget: z.number().optional(),
    pointsShop: z
      .object({
        magicShiftCost: z.number(),
      })
      .optional(),
    extendBookabilityTo: z.number().optional(),
    facilityCancelFills: z
      .object({
        adjustment: z.number(),
        threshold: z.object({
          hours: z.number(),
        }),
      })
      .optional(),
  }),
  [CbhFeatureFlag.SHOW_AVAILABLE_WORKERS_CONFIG]: z.object({ qualifications: z.array(z.string()) }),
  [CbhFeatureFlag.HOME_HEALTH_PT_ENABLED]: z.boolean(),
  [CbhFeatureFlag.WORKER_DOCUMENTS_PAGE_V2_ENABLED]: z.boolean(),
  [CbhFeatureFlag.A_TEAM_CONFIG]: z.object({
    status: z.nativeEnum(Status),
    aTeamReviewCadenceInDays: z.number().optional(),
  }),
  [CbhFeatureFlag.A_TEAM_ROSTER_SIZE]: z.object({
    default: z.number().positive(),
    workerType: z.record(z.number()).optional(),
  }),
  [CbhFeatureFlag.CALENDAR_POPOVER_ENABLED]: z.boolean(),
  [CbhFeatureFlag.SHIFT_INVITE_CUSTOM_RATE_BOOST]: z.boolean(),
  [CbhFeatureFlag.HOME_HEALTH_MENU_ITEMS_HIDDEN]: z.boolean(),
  [CbhFeatureFlag.SHIFT_INVITE_POST_TO_MARKETPLACE_DEFAULT]: z.boolean(),
  [CbhFeatureFlag.ADMIN_APP_GENERIC_OUTAGE_BANNER]: z.union([
    z.object({}),
    z.object({
      severity: z.nativeEnum(GenericOutageBannerSeverity),
      title: z.string(),
      body: z.string(),
    }),
  ]),
  [CbhFeatureFlag.HIDE_BROWSER_WORKERS]: z.boolean(),
  [CbhFeatureFlag.RELOAD_PAGE_ON_USER_SWITCH]: z.boolean(),
  [CbhFeatureFlag.OVERRIDE_FACILITY_REQUIRED_DOCS_FOR_SHIFT_INVITE]: z.boolean(),
  [CbhFeatureFlag.SHOW_QUIZ_ATTEMPTS]: z.boolean(),
  [CbhFeatureFlag.FACLITY_QUIZ_LAYOUT_AB]: z.string(),
  [CbhFeatureFlag.EARLY_CLOCK_IN_ENABLED_IN_MINUTES]: z.object({
    enabledInMinutes: z.number(),
  }),
  [CbhFeatureFlag.RATING_QUALITIES_OPTIONS]: z.record(z.array(z.string())),
  [CbhFeatureFlag.PLACEMENTS_ACCESS_CONTROL_ROLLOUT]: z.nativeEnum(PlacementAccessControlStatus),
  [CbhFeatureFlag.HIDE_NFC_TOGGLE]: z.boolean(),
  [CbhFeatureFlag.SKILL_ASSESSMENTS_GRADERS]: z.object({
    allowedUsers: z.array(z.string()),
  }),
  [CbhFeatureFlag.REQUIREMENT_MODAL_WORKFLOW_TYPE_CHANGES_ROLLOUT]: z.boolean(),
  [CbhFeatureFlag.EXTEND_BOOKABILITY_MODAL_CONFIGURATION]: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showModalIfShiftStartsWithinHours: z.number(),
      minimumShiftDurationHours: z.number(),
      extendBookabilityByHours: z.number(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  [CbhFeatureFlag.ROLLOUT_PARENT_MANAGED_PLACEMENTS]: z.boolean(),
  [CbhFeatureFlag.APP_VERSION_UPDATE_CONTROL]: z.enum(["soft-update", "force-update", "off"]),
  [CbhFeatureFlag.USE_CLIPBOARD_SCORE]: z.boolean(),
  [CbhFeatureFlag.REBRAND_MAX_CONSECUTIVE_HOURS_TO_NO_DOUBLES]: z.boolean(),
  [CbhFeatureFlag.SHIFT_FEEDBACK_QUESTION_EXPERIMENT]: z.object({
    question: z.string().optional(),
    header: z.string().optional(),
    subHeader: z.string().optional(),
    hoursAgoRange: z
      .object({
        min: z.number(),
        max: z.number(),
      })
      .optional(),
    enabled: z.boolean(),
  }),
  [CbhFeatureFlag.DNR_CONFIG]: z.object({
    reasons: z.array(
      z.object({ dnrCharacterFloor: z.number(), label: z.string(), value: z.string() })
    ),
    severity: z
      .object({
        question: z.string(),
        subtext: z.string().optional(),
        response: z.array(z.string()),
      })
      .optional(),
  }),
  [CbhFeatureFlag.WORKER_INSTRUCTION_PANEL_VISIBILITY]: z.boolean(),
  [CbhFeatureFlag.WORKER_LEFT_EARLY]: z.object({
    enabled: z.boolean().default(false),
    replacementFlow: z.object({
      enabled: z.boolean().default(false),
    }),
    canMarkWLEForVerifiedShifts: z.boolean().default(false),
    canMarkWLEForCancelledShifts: z.boolean().default(false),
    minutesBeforeShiftEndForAllowingReplacement: z.number().default(90),
  }),
  [CbhFeatureFlag.ROLLOUT_JOB_INTERVIEWS]: z.boolean(),
  [CbhFeatureFlag.EVENT_PROMOTION_ALERT]: z.object({
    eventDate: z.string().optional(),
    eventLink: z.string().optional(),
    eventTitle: z.string().optional(),
  }),
  [CbhFeatureFlag.LATE_CANCELLATION_LEAD_TIME_THRESHOLD_IN_HOURS]: z.number(),
  [CbhFeatureFlag.CANCELLATION_REASON_CONFIG]: z.object({
    reasons: z.array(
      z.object({
        minDescriptionLength: z.number().optional(),
        label: z.string(),
        relevantShiftTypes: z.array(z.enum(["filled", "unfilled"])),
      })
    ),
    defaultMinDescriptionLength: z.number(),
  }),
  [CbhFeatureFlag.BOOKED_SHIFT_TIME_PROPOSALS]: z.boolean(),
  [CbhFeatureFlag.RANKING_NUDGE_CONFIG]: z.object({
    enabled: z.boolean(),
    nudgeFrequency: z.object({
      count: z.number(),
      durationInHours: z.number(),
    }),
    nudgeSnoozedDurationInHours: z.number(),
  }),
  [CbhFeatureFlag.CANCEL_URGENT_SHIFTS]: z.boolean(),
  [CbhFeatureFlag.BOOKING_REQUESTS_CONFIG]: z.object({
    workplaceIds: z.array(z.string()),
    enabled: z.boolean(),
  }),
  [CbhFeatureFlag.LOWER_CHARGE_RATE_VALIDATION]: z.boolean(),
  [CbhFeatureFlag.SHIFT_BLOCKS_A_TEAM_CONFIG]: z.object({
    enabled: z.boolean(),
  }),
};

export type CbhFeatureFlags = {
  [K in keyof typeof cbhFeatureFlags]: z.infer<typeof cbhFeatureFlags[K]>;
};
