export const API_TYPES = {
  placement: "placement",
  workplace: "workplace",
  worker: "worker",
  signedAgreement: "signed-agreement",
  placementCandidate: "placement-candidate",
  placementApplicant: "placement-applicant",
  placementApplication: "placement-application",
  placementProspect: "placement-prospect",
  license: "license",
  document: "document",
  credential: "credential",
  placementApplicationSummary: "placement-application-summary",
  chatChannel: "chat-channel",
  interviewSettings: "interview-settings",
  passportPdfDownload: "passport-pdf-download",
  ranking: "ranking",
  rankingIncumbent: "ranking-incumbent",
  rankingCandidate: "ranking-candidate",
  shift: "shift",
  shiftTimeProposal: "shift-time-proposal",
  workerLeftEarlyRequest: "worker-left-early-request",
  placementCandidateExclusion: "placement-candidate-exclusion",
} as const;
