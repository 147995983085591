import { createTheme, type Theme } from "@mui/material";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {
  ALERT_COLORS,
  CHIP_COLORS,
  ERROR_COLOR,
  GRAY_00,
  GRAY_1F,
  GRAY_4F,
  GRAY_6B,
  GRAY_E0,
  GRAY_F0,
  LINK_BLUE,
  PRIMARY_COLOR,
  SECONDARY_COLORS,
  TYPOGRAPHY_COLORS,
} from "./constants";
import { TPaletteMode } from "./types";
import { deepPurple, grey } from "@mui/material/colors";

export function getTheme(paletteMode: TPaletteMode): Theme {
  const modeIsLight = paletteMode === "light";
  const textColor = modeIsLight ? GRAY_00 : GRAY_F0;

  return createTheme({
    shape: {
      borderRadius: 8,
    },
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      error: {
        main: ERROR_COLOR,
        light: "#EF5350",
        contrastText: "#FFF1F0",
      },
      warning: {
        main: "#C45700",
      },
      secondary: {
        main: GRAY_6B,
        light: GRAY_E0,
        dark: GRAY_4F,
        contrastText: "#ffffff",
      },
      accent: {
        main: deepPurple[800],
        contrastText: "#ffffff",
      },
      pink: {
        main: "#FF20A6",
        light: "#F4D3EA",
      },
      blue600: {
        main: "#082A74",
        contrastText: "#E8F4FF",
      },
      green600: {
        main: "#169563",
        contrastText: "#E0FBEF",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 400,
        md: 600,
        lg: 900,
        xl: 1400,
      },
    },
    typography: {
      fontWeightLight: 200,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 800,
      allVariants: {
        fontFamily: 'sans-serif, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto"',
        color: textColor,
        fontWeight: 400,
        lineHeight: "inherit",
      },
      h1: {
        fontSize: "1.75rem",
      },
      h2: {
        fontSize: "1.5rem",
      },
      h3: {
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
      },
      h4: {
        fontSize: "1rem",
      },
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
        color: TYPOGRAPHY_COLORS.body2[paletteMode],
      },
      caption: {
        lineHeight: "1rem",
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          underline: "always",
        },
        styleOverrides: {
          root: {
            color: modeIsLight ? LINK_BLUE : SECONDARY_COLORS.dark,
            textDecorationColor: modeIsLight ? LINK_BLUE : SECONDARY_COLORS.dark,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardWarning: ALERT_COLORS.warning,
          standardInfo: ALERT_COLORS.info,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "unset",
          },
          contained: {
            boxShadow: "none",
            "&:hover": {
              color: "white",
              boxShadow: "none",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "8px",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: ERROR_COLOR,
          },
          root: {
            paddingBottom: "4px",
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            width: "100%",
            border: `solid 1px ${grey[300]}`,
            borderRadius: "8px",
            maxHeight: "max-content",
            height: "auto",
            "& .MuiTypography-caption": {
              margin: "auto",
            },
            "& .MuiPickersDay-dayWithMargin": {
              margin: "auto",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: "none",
            "--DataGrid-overlayHeight": "320px",
          },
          columnHeaders: {
            borderBottom: "none",
            backgroundColor: GRAY_1F,
            color: "white",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          },
          cell: {
            "&:focus-within": {
              outline: "none",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: `solid 1px ${grey[300]}`,
            borderRadius: "8px",
            "&::before": {
              display: "none",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: (params) => {
            const {
              ownerState: { color },
            } = params;
            switch (color) {
              case "primary":
                return CHIP_COLORS.primary;

              case "secondary":
                return CHIP_COLORS.secondary;

              case "success":
                return CHIP_COLORS.success;

              case "error":
                return CHIP_COLORS.error;

              case "warning":
                return CHIP_COLORS.warning;

              case "info":
                return CHIP_COLORS.info;

              case "accent":
                return CHIP_COLORS.accent;

              case "blue600":
                return CHIP_COLORS.blue600;

              case "green600":
                return CHIP_COLORS.green600;

              default:
                return {};
            }
          },
        },
      },
    },
  });
}
